<template>
  <el-menu
    :class="{ menus: true, max: langOther }"
    v-if="menusIsShow"
    :default-active="targetNav"
  >
    <!-- enter-active-class="animated fadeInLeft" -->
    <transition-group leave-active-class="animated fadeOutLeft" name="menus">
      <el-menu-item
        v-for="(nav, index_2) in navs"
        :index="nav.category_id.toString()"
        :key="index_2+nav.category_name"
        @click="categoryHandler(nav)"
      >
        <span slot="title">{{ nav.category_name }}</span>
      </el-menu-item>
    </transition-group>
  </el-menu>
</template>
<script>
import http from "@/utils/http";
export default {
  name: "Menus",
  data() {
    return {
      menus: [],
      navs: [],
      language: 1,
      parentId: 0,
      menusIsShow: true,
      targetNav: "",
      langOther: false,
      cid: 0
    };
  },
  computed: {
    watchMenuStatus() {
      return this.$store.state.webStore.menuStatus;
    },
    watchCurrentNav() {
      return this.$store.state.webStore.categoryId;
    },
    watchLang() {
      return localStorage.getItem("langCode");
    },
    watchRoute() {
      return this.$route;
    }
  },
  watch: {
    watchRoute: {
      handler(val) {
        this.cid = this.$store.state.webStore.categoryId;
        this.language = parseInt(window.localStorage.getItem("langCode"));
        if (val.params.t) {
          this.getNewNavs();
          this.getNavs();
        }
      },
      dreep: true
    },
    watchMenuStatus: function(val) {
      this.menusIsShow = val;
    },
    watchCurrentNav: function(val) {
      this.reSetCurrentNav(val);
    },
    watchLang: {
      handler(val) {
        // this.getNavs();
        if (val !== "1" || val !== 1) {
          this.langOther = true;
        }
      },
      dreep: true
    }
  },
  async mounted() {
    this.menusIsShow = this.$store.state.webStore.menuStatus;
    this.cid = this.$store.state.webStore.categoryId;
    this.language = parseInt(window.localStorage.getItem("langCode"));
    if (this.language !== 1) {
      this.langOther = true;
    }

    // let catchNav = JSON.parse(window.localStorage.getItem("catchNavs"));
    this.getNavs();
    if (this.cid) {
      this.reSetCurrentNav(this.cid);
    }
  },
  methods: {
    async reSetCurrentNav(cid) {
      this.targetNav = cid.toString();
      // let self = this;
      // let catchMenu = await self.getNewNavs();
      // if (catchMenu && catchMenu.length) {
      //   catchMenu.forEach((element, index) => {
      //     if (element.category_id == cid) {
      //       self.targetNav = index.toString();
      //     }
      //   });
      // }
    },
    categoryHandler(nav) {
      this.$store.dispatch("commitCateGoryId", nav.category_id);
      let type =
        this.$route.name === "search" ? 6 : this.$store.state.webStore.type;
      let router = {
        params: {}
      };
      let name = "list";
      if (type) {
        switch (type) {
          case 3:
            name = "users";
            break;
          case 4:
          case 5:
            name = "list-ges";
            break;
          case 6:
            name = "search";
            router.params.key = this.$route.params.key;
            break;
        }
        router.params.type = type;
      }
      router.name = name;
      router.params.categoryId = nav.category_id;
      this.jumpPage( router );
    },
    async getNewNavs() {
      let self = this;
      // if (self.navs.length) {
      //   return;
      // }
      let params = {
        language: self.language,
        parentId: self.parentId
      };
      let result = await http({
        withCredentials: false,
        url: "rest/home/v3/getCategories",
        method: "get",
        params: params
      });
      return result.data.data;
    },
    async getNavs() {
      let self = this;

      self.navs = await self.getNewNavs();
      self.navs.unshift({
        category_id: 0,
        category_name: this.$t("all")
      });
      let catchNavs = [];
      self.navs.map(element => {
        let item = {
          id: element.category_id,
          name: element.category_name
        };
        catchNavs.push(item);
      });

      window.localStorage.setItem("catchNavs", JSON.stringify(catchNavs));
      // self.$store.dispatch("commitCateGorys", catchNavs);
    }
  }
};
</script>
<style lang="stylus" scoped="scoped">
.menus {
  // width: 300px;
  min-width: 240px;
  background: #eeeeee;
  flex-flow: column;
  padding: 0.8rem 0;

  &.max {
    min-width: 280px;
  }

  &.el-menu {
    border: 0;

    .el-menu-item {
      white-space: pre-wrap;
      line-height: 18px;
      height: 40px;

      &:hover {
        color: #33cc66;
        // background: #fafafa;
      }

      &.is-active {
        font-weight: 600;
        // background: none;
      }
    }
  }

  li {
    list-style: none;
    min-height: 24px;
    display: flex;
    flex-flow: row;
    align-items: center;
    word-break: break-all;
    // justify-content: center;
  }
}
</style>
