var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.menusIsShow
    ? _c(
        "el-menu",
        {
          class: { menus: true, max: _vm.langOther },
          attrs: { "default-active": _vm.targetNav }
        },
        [
          _c(
            "transition-group",
            {
              attrs: {
                "leave-active-class": "animated fadeOutLeft",
                name: "menus"
              }
            },
            _vm._l(_vm.navs, function(nav, index_2) {
              return _c(
                "el-menu-item",
                {
                  key: index_2 + nav.category_name,
                  attrs: { index: nav.category_id.toString() },
                  on: {
                    click: function($event) {
                      return _vm.categoryHandler(nav)
                    }
                  }
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(nav.category_name))
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }